import React, { useState, useEffect } from 'react';
import serverConf from 'config/server.config';

const EditCharacter = ({
    name,
    description,
    imagePrompt,
    avatar
}) => {

    const [imageUrl, setImageUrl] = useState('https://via.placeholder.com/1024x640');

    useEffect(() => {
        if (avatar) {
            setImageUrl(`${serverConf.HOST}/image/${avatar}`);
        }
    }, [avatar]);

    return (
        <div className='flex flex-row'>
            <div className='flex flex-col items-center w-2/12'>
                <img
                    className={`w-16 h-16 rounded-full`}
                    src={imageUrl}
                    alt={`${name} avatar`}
                />
                <div className="flex items-center space-x-2 rtl:space-x-reverse">
                    <span className="text-base text-center font-semibold text-gray-900 dark:text-white">
                        {name}
                    </span>
                </div>
            </div>
            <div className='w-10/12'>
                <p className="mb-6 text-gray-500 dark:text-gray-400">
                    {description}
                </p>
            </div>
        </div>
    );
};

export default EditCharacter;