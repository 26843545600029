import React, { useEffect, useState } from 'react';
import serverConf from 'config/server.config';

const ChatSidebar = ({
    name,
    description,
    avatar,
    image,
    tags,
}) => {
    const [imageUrl, setImageUrl] = useState('https://via.placeholder.com/1024x640');

    useEffect(() => {
        console.log('Image:', image);
        if (image) {
            setImageUrl(`${serverConf.HOST}/image/${image}`);
            console.log('Image URL:', `${serverConf.HOST}/image/${image}`);
        }
    }, [image]);

    return (
        <div className="flex flex-col space-y-4 h-full justify-center">
            <div className="bg-white dark:bg-gray-800 rounded-lg flex flex-col justify-center items-center">
                {/* Image Section */}
                <div className="relative">
                    <img
                        className="rounded-t-lg w-full"
                        src={imageUrl}
                        alt="Scenario Image"
                    />
                </div>
                <div className="flex flex-col p-4">
                    {/* Text Section */}
                    <h2 className="text-2xl font-bold mb-2 text-gray-900 dark:text-white">
                        {name}
                    </h2>
                    <p className="text-sm text-gray-700 dark:text-gray-300 mb-4">
                        {description}
                    </p>
                </div>
            </div>
            <button className="bg-orange-500 hover:bg-orange-600 text-white py-2 px-4 rounded mb-2 focus:outline-none focus:ring-4 focus:ring-orange-300 dark:focus:ring-orange-500">
                Restart Game
            </button>
            <button className="bg-gray-200 hover:bg-gray-300 text-gray-900 py-2 px-4 rounded focus:outline-none focus:ring-4 focus:ring-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-white dark:focus:ring-gray-500">
                Change Game
            </button>
        </div>
    );
};

export default ChatSidebar;